import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { DawaColors } from "../../themes/Colors"
import media from "../../themes/media"
import HamburgerMenu from "../HamburgerMenu"

import { HeaderImage } from "./headerimage"

const StyledHeader = styled.header`
  position: fixed;
  width: 100%;
  z-index: 2;
  box-shadow: 0px 0px 9px 3px rgba(41, 41, 41, 0.25);
  background: white;
  padding: 0px;
  ${media.tablet`
    padding: 56px 119px 36px;
  `};
`
const HeaderContents = styled.div`
  ${media.tablet`
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-weight: 600;
  `};
`
export const StyledLink = styled(Link)`
  color: #000000;
  font-size: 22px;
  text-decoration: none;
  margin-right: 30px;
  &:hover {
    color: ${DawaColors.blue};
  }
  &:active {
    color: ${DawaColors.blue};
  }
`
export const HeaderLinkWrapper = styled(Link)`
  height: 100%;
`
const LinksContainer = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  justify-content: start;
  background: ${DawaColors.white};
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
  height: 100vh;
  text-align: right;
  padding: 2rem;
  position: absolute;
  top: 79px;
  right: 0;
  transition: transform 0.3s ease-in-out;
  a {
    padding: ${({ open }) => (open ? "16px 0" : "unset")};
  }
  ${media.tablet`
    float: right;
    display: block;
    flex-direction: unset;
    justify-content: unset;
    background: unset;
    transform:unset;
    height: unset;
    text-align: unset;
    padding: unset;
    position: unset;
    top: unset;
    right: unset;
    transition: unset;
  `};
`
const Header = ({ onClick, open }) => (
  <StyledHeader>
    <HeaderContents>
      <HamburgerMenu onToggle={onClick} />
      <HeaderLinkWrapper to="/">
        <HeaderImage />
      </HeaderLinkWrapper>
      <LinksContainer open={open}>
        <StyledLink to="/">About Us</StyledLink>
        <StyledLink to="/">Our Impact</StyledLink>
        <StyledLink to="/">Get Involved</StyledLink>
        <StyledLink to="/">Contact Us</StyledLink>
        <StyledLink to="/">Donate</StyledLink>
      </LinksContainer>
    </HeaderContents>
  </StyledHeader>
)

export default Header
