import React, { Fragment } from "react"
import styled from "styled-components"
import { DawaColors } from "../../themes/Colors"
import media from "../../themes/media"

import facebook from "../../images/footer/facebook-square-color.svg"
import twitter from "../../images/footer/twitter-square-color.svg"
import linkedin from "../../images/footer/linkedin-square-color.svg"
import { StyledLink as Link } from "../Header/header"

const StyledFooter = styled.footer`
  background: ${DawaColors.grey};
  padding: 32px 20px;
  ${media.tablet`
    padding: 32px 150px;
  `};
`
const ListItem = styled.p`
  margin-bottom: unset;
  font-size: 16px;
`
const Col = styled.div`
  position: relative;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  margin: 24px 12px;
  ${media.tablet`
    width: 33%;
    flex-direction: row;
    margin: 12px;
  `};
  p {
    font-size: 16px;
  }
`
const OrganizationDetails = styled.footer`
  background-color: ${DawaColors.white};
  font-size: 16px;
  color: ${DawaColors.black};
  padding: 32px 20px;
  ${media.tablet`
    padding: 32px 150px;
  `};
`
const Row = styled.div`
  ${media.tablet`
    display: flex;
  `};
`
const Logo = styled.img`
  display: block;
  height: 40px;
  object-fit: contain;
`
const StyledLink = styled(Link)`
  font-size: 16px;
`
const Footer = () => {
  return (
    <Fragment>
      <StyledFooter>
        <Row>
          <Col>
            <strong>dawaCare</strong>
            <ListItem>1026 Elder Ave, Apt 1 Bronx, NY 10472</ListItem>
            <ListItem>US: +001 347 - 857 - 5178</ListItem>
            <ListItem>
              CMR: +237 693 - 363 - 216 or +237 679 - 100 - 960
            </ListItem>
          </Col>
          <Col>
            <strong>Useful Links</strong>
            <ListItem>
              <StyledLink to="/">Home</StyledLink>
            </ListItem>
            <ListItem>
              <StyledLink to="/">About Us</StyledLink>
            </ListItem>
            <ListItem>
              <StyledLink to="/">Impact</StyledLink>
            </ListItem>
            <ListItem>
              <StyledLink to="/">Get Involved</StyledLink>
            </ListItem>
            <ListItem>
              <StyledLink to="/">Contact Us</StyledLink>
            </ListItem>
            <ListItem>
              <StyledLink to="/">Donate</StyledLink>
            </ListItem>
          </Col>
          <Col>
            <strong>Our Social Networks</strong>
            <p>Feel free to connect with us via social media on</p>
            <Row>
              <Col>
                <a
                  href="https://www.facebook.com/dawacare2017"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Logo src={facebook} />
                </a>
              </Col>
              <Col>
                <a
                  href="https://twitter.com/dawaCare"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Logo src={twitter} />
                </a>
              </Col>
              <Col>
                <a
                  href="https://www.linkedin.com/company/11157697"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Logo src={linkedin} />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledFooter>
      <OrganizationDetails>
        dawaCare, Inc 2020. A 501(c) 3 Organization
      </OrganizationDetails>
    </Fragment>
  )
}

export default Footer
