import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import media from "../../themes/media"

const StyledImg = styled(Img)`
  height: 100%;
  width: 100px;
  top: 20px;
  margin-left: 20px;
  ${media.tablet`
    top: unset;
    margin-left: unset;
    margin-right: 30px;
  `};
`

const HeaderImage = () => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "dawa_logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => <StyledImg fluid={data.logo.childImageSharp.fluid} />}
  />
)

export { HeaderImage }
