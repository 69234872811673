import styled from "styled-components"
import React from "react"

import media from "../../themes/media"

const HamburgerMenuLine = styled.div`
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 6px 0;
  display: block;
`
const Menu = styled.div`
  margin: 20px;
  float: right;
  ${media.tablet`
    display: none;
  `};
`
const HamburgerMenu = ({ onToggle, open }) => (
  <Menu onClick={() => onToggle(!open)}>
    <HamburgerMenuLine />
    <HamburgerMenuLine />
    <HamburgerMenuLine />
  </Menu>
)

export default HamburgerMenu
